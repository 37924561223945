import PolarToCarthesian from './polar_carthesian.js';
import SVG from './svg.js';

const homeContent = document.querySelectorAll('.home__content');
const radius = 100;

const coords = new PolarToCarthesian(radius, homeContent.length);

const viewBox = '-120 -120 240 240';
const cssClass = 'ring';
const svgConstructor = new SVG(coords.points(), cssClass, viewBox, radius);
const svg = svgConstructor.assembleSvg();
const ring = document.querySelector(`.${cssClass}__container`);
ring.appendChild(svg);

const calcWidth = (width, height) => {
  const C2 = (0.5 * width) ** 2;
  const B2 = (0.5 * width - height) ** 2;
  const returnVal = 2 * Math.sqrt(C2 - B2);
  return returnVal;
};

const innerCircleTextSizes = () => {
  const innerCircle = document.querySelector('.ring__inner').getBoundingClientRect();
  const { height } = innerCircle;
  const { width } = innerCircle;

  document.querySelectorAll('.home__paragraph').forEach((element) => {
    element.setAttribute('style', `max-width: ${calcWidth(width, 150)}px; max-height: ${height}px;`);
  });
  document.querySelectorAll('.home__title').forEach((element) => {
    element.setAttribute('style', `max-width: ${calcWidth(width, 100)}px;`);
  });

  document.querySelectorAll('.home__content').forEach((element) => {
    element.setAttribute('style', `width: ${width - 30}px; max-height: ${height - 75}px;`);
  });
};

function setCircleSize() {
  const circles = document.querySelectorAll('.ring__circle');
  if (window.innerWidth >= 768) {
    circles.forEach((element) => {
      element.setAttribute('r', '2.5');
    });
  } else {
    circles.forEach((element) => {
      element.setAttribute('r', '10');
    });
  }
}

if (window.innerWidth >= 768) {
  setCircleSize();
  innerCircleTextSizes();
}

window.addEventListener('resize', () => {
  if (window.innerWidth >= 768) {
    setCircleSize();
    innerCircleTextSizes();
  }
});
